import * as React from "react";
import { Page } from "../components/Page";
import { graphql } from "gatsby";
import Image from "gatsby-image";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";

import { ContentfulResponse } from "../utils/queryTypes";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { ProgressIndicator } from "../components/ProgressIndicator";

interface Response {
  body: {
    raw: string;
    references: any;
  };
  tag: string;
  title: string;
  updatedAt: string;
}

export default ({
  data
}: {
  data: ContentfulResponse<"contentfulBlogPosts", Response>;
}) => {
  const { title, tag, updatedAt, body } = data.contentfulBlogPosts;
  const formattedDate = new Intl.DateTimeFormat("en-GB").format(
    new Date(updatedAt)
  );

  const rawBody = {
    raw: body.raw,
    references: undefined
  };

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <Page>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div
        style={{
          minHeight: isTabletOrMobile ? "50vh" : "70vh",
          backgroundColor: "rgb(105, 40, 181)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div
          style={{
            marginLeft: "10%",
            marginRight: "10%",
            textAlign: "center",
            color: "white"
          }}
        >
          <h1
            style={{
              fontSize: isTabletOrMobile ? 65 : 96,
              borderBottom: "none"
            }}
          >
            {title}
          </h1>
          <h5 style={{ textTransform: "uppercase" }}>{tag}</h5>
          <h5>{formattedDate}</h5>
        </div>
      </div>

      <div style={{ minHeight: "50px" }} />
      {renderArticleContent(isTabletOrMobile, rawBody, body.references)}
    </Page>
  );
};

export const query = graphql`
  query BlogPost($id: String) {
    contentfulBlogPosts(id: { eq: $id }) {
      body {
        raw
        references {
          fluid(quality: 70, maxWidth: 2000) {
            srcSet
            src
            srcSetWebp
            srcWebp
            aspectRatio
            sizes
          }
          contentful_id
          description
        }
      }
      tag
      title
      updatedAt
    }
  }
`;

const renderArticleContent = (
  isTabletOrMobile: boolean,
  rawBody: any,
  bodyReferences: any
) => {
  const baseContent = (
    <div
      style={{
        fontSize: isTabletOrMobile ? 16 : 24
      }}
    >
      {renderRichText(rawBody, richTextOptions(bodyReferences))}
    </div>
  );

  if (isTabletOrMobile) {
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "100%",
          paddingLeft: "10%",
          paddingRight: "10%"
        }}
      >
        {baseContent}
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "grid",
          gap: "2%",
          gridTemplateColumns: "23% 50% 23%"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-start"
          }}
        >
          <ProgressIndicator />
        </div>
        {baseContent}
      </div>
    );
  }
};

const richTextOptions = (bodyReferences: any[]) => ({
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      if (Object.keys(node.data).length === 0) {
        return <div />;
      }
      const contentfulId = node.data.target.sys.id;
      const reference = bodyReferences.find(
        (asset) => asset.contentful_id === contentfulId
      );
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            paddingTop: "10px",
            paddingBottom: "10px",
            flexDirection: "column"
          }}
        >
          <Image
            style={{ width: "75%", borderRadius: "4px" }}
            fluid={reference.fluid}
          />
          {reference.description && (
            <p style={{ paddingTop: 10 }}>
              <i>{reference.description}</i>
            </p>
          )}
        </div>
      );
    },
    [INLINES.HYPERLINK]: (node) => {
      const link = node.data.uri;
      const text = node.content[0].value;
      return (
        <a href={link} style={{ color: "#4078c0" }}>
          {text}
        </a>
      );
    }
  },
  renderMark: {
    [MARKS.CODE]: (text) => <code style={{ fontSize: "80%" }}>{text}</code>
  }
});
