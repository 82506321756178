import * as React from "react";
import { motion, useViewportScroll, useSpring } from "framer-motion";

export const ProgressIndicator = () => {
  const { scrollYProgress } = useViewportScroll();
  const pathLength = useSpring(scrollYProgress, {
    stiffness: 400,
    damping: 90
  });

  return (
    <div
      style={{
        position: "sticky",
        width: "25%",
        top: 10
      }}
    >
      <svg viewBox="0 0 60 60">
        <motion.path
          fill="none"
          strokeWidth="3"
          stroke="rgb(64, 182, 110)"
          strokeDasharray="0 1"
          d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
          style={{
            pathLength,
            rotate: 90,
            translateX: 5,
            translateY: 5,
            scaleX: -1
          }}
        />
      </svg>
    </div>
  );
};
